/* You can add global styles to this file, and also import other style files */
// this is our just created themes.scss file, make sure the path to the file is correct
@use "themes" as *;

// framework component styles
@use "/node_modules/@nebular/theme/styles/globals" as *;

// install the framework styles
@include nb-install() {
  @include nb-theme-global();
}

//custom variable
$primary: #1a8fe3;
$gradient: linear-gradient(
  180deg,
  rgba(rgb(204, 204, 204), 0.25),
  rgba(black, 0)
);
$red: #ff3d71;

// $primary: #3e54ff;
// $secondary: #188f2c;

//import functions and variables
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$custom-colors: (
  "primary-100": #cfe2ff,
  "secondary-100": #d1e7dd,
  "red-100": #f8d7da,
  "indigo-100": #e0cffc,
  "purple-100": #e2d9f3,

  //b try
  // "first": #becae6,
  // "second": #219ebc,
  // "third": #023047,
  // "fourth": #ffb703,
  // "fifth": #f88500,
  //f try
  // "first": #BECAE6,
  // "second": #219EBC,
  // "third": #023047,
  // "fourth": #FFB703,
  // "fifth": #F88500,
  //second try
  // "first": #e63946,
  // "second": #f1faee,
  // "third": #a8dadc,
  // "fourth": #45789d,
  // "fifth": #1d3557,
  //first try
  "first": rgb(26, 143, 227),
  "second": rgb(102, 16, 242),
  "third": rgb(209, 17, 73),
  "fourth": rgb(241, 113, 5),
  "fifth": rgb(230, 194, 41),
  "sixth": rgb(30, 30, 30),
  "seventh": rgb(24, 143, 44),
  // 0 try
  // "first": #035397,
  // "second": #001E6C,
  // "third": #E8630A,
  // "fourth": #FCD900,
  // "fifth": #7A2398,
  "first-100": #88cdff,
  // "second-100": #904aff,
  "second-100": #ffab4b,
  "third-100": #ff5686,
  "fourth-100": #f17105,
  "fifth-100": #e6c229,
  // "second-200": #bf97ff,
  "second-200": #efc18d,
  "third-200": #e991aa,
  "fourth-200": #e2904c,
  "white": #ffffff,

  ///dark mode colors
  "black": black,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

/* Importing Bootstrap SCSS file. */

@import "../node_modules/bootstrap/scss/bootstrap";
@import "~katex/dist/katex.css";

button {
  text-transform: unset !important;
}

.main-container-fixed {
  top: 1rem !important;
}

.form-val-error {
  color: red;
}

html {
  scroll-behavior: smooth;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow: hidden !important;
}

// body {
//   font-family: "Roboto", sans-serif !important;
// }
